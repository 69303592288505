<template>
    <div id="homeContainer" class="home_container">
        <!-- Modal -->
        <home-modal v-if="isOpenHomeModal" @close-modal="onCloseHomeModal" />
        <!--Header-->
        <home-header
            :activeindex="activeIndex"
            @open-inquiry="onOpenInquiry"
            @click-nav="onClickNav"
            @click-gopage="onClickGoPage"
        />
        <section class="home_section1">
            <home-section1 />
        </section>
        <section class="home_section2">
            <home-section2 />
        </section>
        <section class="home_section3" id="homeSection3">
            <home-section3 />
        </section>
        <section class="home_section4" id="homeSection4">
            <home-section4 />
        </section>
        <section class="home_section5" id="homeSection5">
            <home-section5 />
        </section>
        <section class="home_section6">
            <home-section6 @open-inquiry="isOpenHomeModal = true" />
        </section>
        <home-footer />
    </div>
</template>
<script>
import { WOW } from 'wowjs';

export default {
  name: 'ReNewalHome',
  components: {
    'home-header': () => import(`@/components/home/HomeHeader.vue`),
    'home-section1': () => import(`@/components/home/HomeSection1.vue`),
    'home-section2': () => import(`@/components/home/HomeSection2.vue`),
    'home-section3': () => import(`@/components/home/HomeSection3.vue`),
    'home-section4': () => import(`@/components/home/HomeSection4.vue`),
    'home-section5': () => import(`@/components/home/HomeSection5.vue`),
    'home-section6': () => import(`@/components/home/HomeSection6.vue`),
    'home-footer': () => import(`@/components/home/HomeFooter.vue`),
    'home-modal': () => import(`@/components/home/HomeModal.vue`)
  },
  data () {
    return {
      wow: null,
      isOpenHomeModal: false,
      activeIndex: 0
    };
  },

  mounted () {
    this.wow = new WOW({ live: false });
    this.wow.init();
    this.wow.sync();

    this.$nextTick(() => {
      if (Object.keys(this.$route.params).length > 0) {
        this.onClickNav(this.$route.params);
        const { index } = this.$route.params;
        this.activeIndex = index;
      }
      window.addEventListener('scroll', this.onScrollEvent);
    });
  },

  methods: {
    onScrollEvent () {
      const currentScrollY = window.scrollY + 100;
      // section1의 범위
      const section1Start = 0;
      const section1End = document.querySelector('.home_section1').clientHeight;

      // section2의 범위
      const section2Start = document.querySelector('.home_section2').offsetTop;
      const section2End = section2Start + document.querySelector('.home_section2').clientHeight;

      // section3의 범위
      const section3Start = document.querySelector('.home_section3').offsetTop;
      const section3End = section3Start + document.querySelector('.home_section3').clientHeight;

      // section4의 범위
      const section4Start = document.querySelector('.home_section4').offsetTop;
      const section4End = section4Start + document.querySelector('.home_section4').clientHeight;

      // section5의 범위
      const section5Start = document.querySelector('.home_section5').offsetTop;
      const section5End = section5Start + document.querySelector('.home_section5').clientHeight;

      // section6의 범위
      const section6Start =
        document.querySelector('.home_section6').offsetTop + document.querySelector('.home_section6_wrap').clientHeight;
      const section6End = section6Start + document.querySelector('.home_container').clientHeight;

      if (section1Start < currentScrollY && section1End > currentScrollY) {
        this.activeIndex = 0;
      } else if (section2Start < currentScrollY && section2End > currentScrollY) {
        this.activeIndex = 0;
      } else if (section3Start < currentScrollY && section3End > currentScrollY) {
        this.activeIndex = 1;
      } else if (section4Start < currentScrollY && section4End > currentScrollY) {
        this.activeIndex = 2;
      } else if (section5Start < currentScrollY && section5End > currentScrollY) {
        this.activeIndex = 3;
      } else if (section6Start < currentScrollY && section6End > currentScrollY) {
        this.activeIndex = 4;
      }
    },
    onOpenInquiry () {
      this.isOpenHomeModal = true;
    },
    onClickGoPage (name) {
      this.$router.push({ name }).catch(() => {});
    },
    onClickNav (params) {
      const { id } = params;
      if (id) {
        const ele = document.querySelector(`#${id}`);
        if (ele) {
          ele.scrollIntoView({
            block: 'start'
          });
          this.isOpenMobileNav = false;
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    onCloseHomeModal () {
      this.isOpenHomeModal = false;
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScrollEvent);
  }
};
</script>
<style src="@/assets/css/home/homefont.css"></style>
<style>
.animate__aplayzFadeInUp {
  animation-name: aplayzFadeInUp;
}

@keyframes aplayzFadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
<style scoped src="@/assets/css/home/renewalhome.css"></style>
